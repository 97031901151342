import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NamesService } from 'src/app/services/names.service';
import { HafkadotService } from 'src/app/services/hafkadot.service';
import { MafkidService } from 'src/app/services/mafkid.service';
import { LoansService } from 'src/app/services/loans.service';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Hok } from 'src/app/classes/hok';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { NeztigimService } from 'src/app/services/neztigim.service';
import { HokHelpService } from 'src/app/services/hok-help.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  //#region 
  degel: boolean;
  degel1: boolean;
  PERMISSION: any;
  iduser: any;
  reports: boolean = false;
  columnDefs;
  gridOptions: { columnDefs: any; rowSelection: string; rowData: any; };
  rowData: any;
  list: any;
  listnames: any;
  listHafkadot: any;
  listLoan: any;
  listHok: any;
  hokN: Hok = new Hok();
  listCoins: any;
  rowSelection: any;
  localeText: any;
  listTypes: any;
  loansB: boolean;
  wait: boolean;
  reports1: boolean = false;
  listHeczers: any;
  listMaf: any;
  gridApi: any;
  LoansWithworngBalance: any;
  LoansWithlowBalance: any;
  LoansWithhighwBalance: any;
  listcontacts: any;
  listmaslul: any;
  listhokhelps: any;
  LoansWithContact: any;
  loansDollar: number=0;
  list1: any;
  list2: any;
  loansILS:number=0;
  Contact:any;
  ;
  //#endregion
  constructor(private router: Router, private route: ActivatedRoute, private maagar: MaagarMenageService, private name: NamesService, private haf: HafkadotService, private maf: MafkidService, private loans: LoansService, private hok: HokMenageService,private netzig:NeztigimService,private hokhelp:HokHelpService) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.iduser = params.iduser;
    }
    )
  }
  ngOnInit() {
    // this.reports=false;
    //  this.reports1=false;
    this.degel = true;
    this.degel1 = true;
    this.ShowNames();
    // this.ShowHekHelps()
     this.ShowNetzig();
//this.showmaslul();
    //  this.ShowLoans();
    //  this.ShowTypes();
    //  this.ShowCoins();
    this.ShowHok();
  //  this.showMaf();
    // this.showHeczers();
    // this.showHafkadot();
    // this.ShowLoansWithworngBalance();
  }
  ShowHekHelps() {
    this.hokhelp.GetAll().then(res => {
      this.listhokhelps = res
    });  }
  ShowNetzig() {
    this.netzig.Shownetzigim().then(res => {
      this.listcontacts = res;
      this.listcontacts.forEach(element=>
      element['Name'] = element['FamilyName']+ " " + element['FirstName']
      )
    });  }
    showmaslul() {
      this.maf.getmasluls().then(res => {
        this.listmaslul = res
      });  }
  ShowLoansWithworngBalance() {
    this.Contact=false;

    this.name.GetLoansWithWorngBalance().then(res => {
      this.LoansWithworngBalance = res
    });
  }
  LoansByContact() {
    this.Contact=true;
    this.name.GetLoansWithByConcat().then(res => {
      this.LoansWithContact = res
      this.LoansWithContact.forEach(element => {
        element['name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : null;
        element['CoinName'] = this.listCoins && this.listCoins.find(a => a.Id == element.Coin) ? this.listCoins.find(a => a.Id == element.Coin).Name : null;
        element['typeloanname'] = this.listTypes && this.listTypes.find(a => a.Id == element.typeloan) ? this.listTypes.find(a => a.Id == element.typeloan).Name : null;
        element['ContactName'] = this.listcontacts && this.listcontacts
        .find(a => a.MAINID == element.MAINIDContact) ? this.listcontacts
        .find(a => a.MAINID == element.MAINIDContact).Name : null;
        debugger
      }
      )
      this.columnDefs = [
        { headerName: 'שם לווה', sortable: true, filter: "agTextColumnFilter", field: 'name', width: 180 },
        {
          headerName: 'תאריך לועזי', sortable: true, field: 'loanDateforien', filter: "agTextColumnFilter", width: 140, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        { headerName: 'תאריך עברי', field: 'loanDate', filter: "agTextColumnFilter", width: 140 },
        { headerName: 'סכום ', sortable: true, field: 'LoanAmount', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'יתרה', field: 'Balance', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'מטבע', sortable: true, field: 'CoinName', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'תשלומים', field: 'Payments', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'הערה', field: 'notice', width: 100, filter: "agTextColumnFilter" },
        { headerName: 'סוג הלוואה', field: 'typeloanname', filter: "agTextColumnFilter", width: 140 },
         {headerName: 'נציג', field: 'ContactName',filter: "agTextColumnFilter" ,width: 300},
      ];
      this.list = this.LoansWithContact;
      this.list1 = this.list.filter(a => a.Coin == 1);
    this.list1.forEach(element => {
      this.loansILS += element.Balance;
    });
    this.list2 = this.list.filter(a => a.Coin == 2);
    this.list2.forEach(element => {

      this.loansDollar += element.Balance;
    });
    debugger

      this.buildgrid();
    
       });  }
  showHafkadot() {
    this.Contact=false;

    this.maf.getListHafkadot().then(res => { 
      this.listHafkadot = res 
      this.columnDefs = [
        { headerName: 'שם מפקיד', sortable: true, filter: "agTextColumnFilter", field: 'Name', width: 130 },
        {
          headerName: 'תאריך לועזי', sortable: true, field: 'DateForiegnHAfkada', sort: 'asc', filter: "agTextColumnFilter", width: 130, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        { headerName: 'תאריך הפקדה', field: 'DateHafkada', filter: "agTextColumnFilter", width: 130 },
        { headerName: 'סכום ההפקדה', sortable: true, field: 'Amount', filter: "agNumberColumnFilter", width: 100 },
        { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 100 },
        { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: "agNumberColumnFilter", width: 100 },
        { headerName: 'מטבע', sortable: true, field: 'CoinName', filter: "agTextColumnFilter", width: 100 },
        { headerName: 'מטרה', field: 'TypeOperation', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'סטטוס הפקדה', field: 'StatusHafkada', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'אמצעי תשלום', field: 'getbyway', filter: "agTextColumnFilter", width: 90 },
  
        { headerName: 'הערה', field: 'Notice', filter: "agTextColumnFilter", width: 100 },
        { headerName: 'אסמכתא', field: 'ASMACTA', filter: "agTextColumnFilter", width: 100 },
        { headerName: 'מס"ב', field: 'MASAV', filter: "agTextColumnFilter", width: 100 },
      ];})
      this.rowData=this.listHafkadot;

  }
  showHeczers() {
    this.Contact=false;

    this.loans.getListHeczers().then(res => { this.listHeczers = res })
  }
  showMaf() {
    this.Contact=false;

    this.maf.GetAllMaf().then(res => { this.listMaf = res })
  }
  ShowNames() {
    this.listnames = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.listnames == null) {
      this.name.ShowNames().then(res => {
        this.listnames = res
        //   this.ShowTypes();
        // this.ShowHok();
      })
    };
  }
  ShowTypes() {
    this.maagar.getTypesLoan().then(res => {
      this.listTypes = res
      // this.ShowCoins();
    })
  }
  ShowCoins() {
    this.listCoins = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listCoins == null) {
      this.maagar.GetCoins().then(res => {
        this.listCoins = res
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listCoins));


      });
    }
  }
  ShowShowNHaf() {
    this.haf.showHAfkadot().then(res => { this.listHafkadot = res });
  }
  ShowLoans() {
    this.name.ShowLoans().then(res => { this.listLoan = res });
  }
  ShowHok() {
    this.hok.GetHoksAll().then(res => { this.listHok = res });
  }
  heczer() {
    this.reports = false;
    this.name.ShowLoans().then(res => {
      this.listLoan = res
      this.listLoan.forEach(element => {
        this.hokN = new Hok();
        this.hokN = this.listHok.find(a => a.MAINID == element.MAINID);
        if (this.hokN != null) {
          if (this.hokN.TypeHok == "הוק") {
            element['bank'] = this.hokN.BANK;
            element['snif'] = this.hokN.SNIF;
            element['account'] = this.hokN.BANKACCOUNT;
          }
        }
        element['card'] = this.hokN.CardNumber;
        element['valid'] = this.hokN.Valid;
      })

      this.columnDefs = [
        { headerName: 'שם לווה', sortable: true, filter: "agTextColumnFilter", field: 'Name', width: 130, },
        { headerName: 'שם מחזיר', sortable: true, filter: "agTextColumnFilter", field: 'NameM', width: 130, },
        {
          headerName: 'תאריך לועזי', sortable: true, field: 'DateForiegnHeczer', filter: "agTextColumnFilter", width: 130, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        { headerName: 'תאריך עברי', field: 'DateHeczer', filter: "agTextColumnFilter", width: 130, },
        { headerName: 'סכום ', sortable: true, field: 'LoanAmount', filter: "agNumberColumnFilter", width: 100, },
        { headerName: 'מטבע', sortable: true, field: 'Coin', filter: "agTextColumnFilter", width: 80, },
        { headerName: 'תשלומים', field: 'Payments', filter: "agTextColumnFilter", width: 90, },
        { headerName: 'הערה', field: 'notice', filter: "agTextColumnFilter", width: 100, },
        { headerName: 'אמצעי תשלום', field: 'notice', filter: "agTextColumnFilter", width: 100, },
        { headerName: 'סטטוס', field: 'StatusHeczer', "agTextColumnFilter": true, width: 100, },
        { headerName: 'בנק', field: 'bank', filter: "agTextColumnFilter", width: 90, },
        { headerName: 'סניף', field: 'snif', filter: "agTextColumnFilter", width: 100, },
        { headerName: ' חשבון', field: 'account', filter: "agTextColumnFilter", width: 100, },
        { headerName: 'כרטיס', field: 'card', filter: "agTextColumnFilter", width: 100, },
        { headerName: ' תוקף', field: 'valid', filter: "agTextColumnFilter", width: 100, }
      ];
      this.buildgrid();
    });
  }
  hok1() {
    this.Contact=false;

    this.reports = false;
    this.loansB = false;
    if (this.listHok != null) {
      // this.wait=false;

      this.listHok.forEach(element => {
        element['name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : null;

      });

      this.columnDefs = [
        { headerName: 'שם לווה', sortable: true, field: 'name', filter: "agTextColumnFilter" },
        { headerName: 'בנק', field: 'BANK', filter: "agTextColumnFilter", },
        { headerName: 'סניף', field: 'SNIF', filter: "agTextColumnFilter" },
        { headerName: ' חשבון', field: 'BANKACCOUNT', filter: "agTextColumnFilter" },
        { headerName: 'כרטיס', field: 'CardNumber', filter: "agTextColumnFilter" },
        {
          headerName: ' תוקף', field: 'Valid', filter: "agTextColumnFilter", cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        {
          headerName: ' סטטוס', field: 'Accepted', filter: "agTextColumnFilter", width: 100,

          cellRenderer: params => {
            return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
          }
        },
        {
          headerName: 'תאריך עדכון', sortable: true, field: 'UpdateDate', filter: "agNumberColumnFilter", width: 130, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        {
          headerName: 'תאריך יצירה', sortable: true, field: 'CreateDate', filter: "agNumberColumnFilter", width: 130, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },

      ];
      this.list = this.listHok;
      this.buildgrid();
    }
    else {
      // this.wait=true;
    }
  }
  excel() {
    ;
    this.gridApi.exportDataAsCsv(this.gridApi.rowModel.rowsToDisplay);
  }
  loan() {
    this.loansB = true;
    this.reports = false;
    if (this.LoansWithworngBalance != null) {
      this.wait = false;
      this.LoansWithworngBalance.forEach(element => {
        element['name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : null;
        element['CoinName'] = this.listCoins && this.listCoins.find(a => a.Id == element.Coin) ? this.listCoins.find(a => a.Id == element.Coin).Name : null;
        element['typeloanname'] = this.listTypes && this.listTypes.find(a => a.Id == element.typeloan) ? this.listTypes.find(a => a.Id == element.typeloan).Name : null;

      }
      )
      this.columnDefs = [
        { headerName: 'שם לווה', sortable: true, filter: "agTextColumnFilter", field: 'name', width: 180 },
        {
          headerName: 'תאריך לועזי', sortable: true, field: 'loanDateforien', filter: "agTextColumnFilter", width: 140, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        { headerName: 'תאריך עברי', field: 'loanDate', filter: "agTextColumnFilter", width: 140 },
        { headerName: 'סכום ', sortable: true, field: 'LoanAmount', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'יתרה', field: 'Balance', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'מטבע', sortable: true, field: 'CoinName', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'תשלומים', field: 'Payments', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'הערה', field: 'notice', width: 300, filter: "agTextColumnFilter" },
        { headerName: 'סוג הלוואה', field: 'typeloanname', filter: "agTextColumnFilter", width: 140 },
        // {headerName: 'הערה', field: 'notice',filter: "agTextColumnFilter" ,width: 100},
      ];
      this.list = this.listLoan;
      this.buildgrid();
    }
    else {
      // this.wait=true;
      this.reports1 = false;
    }
  }
  haf1() {
    
  }
  maf1() {
    debugger
    this.reports = false;
    this.maf.GetAllMaf().then(res => {
      this.listMaf = res
  this.listMaf.forEach(element => {
    element['contactName'] = this.listcontacts && this.listcontacts.find(a => a.MAINID == element.contact) ? this.listcontacts.find(a => a.MAINID ==  element.contact).FamilyName + '  ' + this.listcontacts.find(a => a.MAINID ==  element.contact).FirstName : null;
    element['MaslulName'] = this.listmaslul && this.listmaslul.find(a => a.KodMaslul == element.IdMaslul) ? this.listmaslul.find(a => a.KodMaslul == element.IdMaslul).remark : null;

    element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : null;
  });
debugger
      this.columnDefs = [
        { headerName: 'שם מפקיד', sortable: true, filter: "agTextColumnFilter", field: 'Name', width: 130, },
        { headerName: 'הערה', field: 'Remark', filter: "agTextColumnFilter", width: 400, },
        { headerName: 'פעיל', field: 'pail', filter: "agTextColumnFilter", width: 90,  cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
        }},
   
        { headerName: 'מס יחידות', field: 'NUMYACHIDOT', filter: "agNumberColumnFilter", width: 100, },
        { headerName: 'נציג', field: 'contactName', filter: "agTextColumnFilter", width: 180, },
        { headerName: 'הצטרף עי', field: 'JoinBy', filter: "agTextColumnFilter", width: 180, },
        { headerName: 'מסלול', field: 'MaslulName', filter: "agTextColumnFilter", width: 250, },
        { headerName: 'תאריך', field: 'CreateDate', filter: "agTextColumnFilter", width: 250, }

      ];
      this.list=this.listMaf
      this.buildgrid();
    });
  }
  hokhelps()
  {
    this.reports = false;

  this.listhokhelps.forEach(element => {
  
    element['Name'] = this.listnames && this.listnames.find(a => a.MAINID == element.mainid) ? this.listnames.find(a => a.MAINID == element.mainid).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.mainid).FirstName : null;
  });
debugger

      this.columnDefs = [
        { headerName: 'שם מפקיד', sortable: true, filter: "agTextColumnFilter", field: 'Name', width: 130, },
        { headerName: 'סכום', field: 'Scum', filter: "agNumberColumnFilter", width: 400, },
        { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 400, },

        { headerName: 'מס תשלומים', field: 'MisTashlumim', filter: "agNumberColumnFilter", width: 400, },
        { headerName: 'כל שנה', field: 'entereveryyear', filter: "agTextColumnFilter", width: 90,  cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
        }},
        { headerName: 'פעיל', field: 'pail', filter: "agTextColumnFilter", width: 90,  cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
        }},
        { headerName: 'שפיצר', field: 'shpitzer', filter: "agTextColumnFilter", width: 90,  cellRenderer: params => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
        }}, 
        { headerName: 'שער', field: 'Rate', filter: "agNumberColumnFilter", width: 400, },

    
        { headerName: 'תאריך', field: 'StartDate', filter: "agTextColumnFilter", width: 250, }

      ];
      this.list=this.listhokhelps
      this.buildgrid();
  
  }

  viewdetails(event) {
    if (this.loansB) {
      const url = this.router.serializeUrl(this.router.createUrlTree(["/loans/" + this.PERMISSION, this.iduser, , event.data.MAINID, event.data.ID]));
      window.open(url, '_blank');


    }
  }
  buildgrid() {
    this.reports1 = true;
    this.columnDefs = this.columnDefs,
      this.rowSelection = 'single',
      this.rowData = this.list,
      this.localeText = LOCALE_TEXT

  }

  onGridReady(event: any) {
    this.gridApi = event.api;

  }
  report2() {
    this.reports = true;
  }
  showmonth() {
    ;

    if (this.degel == true) {
      this.degel = false;
    }
    else {
      this.degel = true;
    }
  }
  showyears() {
    if (this.degel1 == true) {
      this.degel1 = false;
    }
    else {
      this.degel1 = true;
    }
  }
  loanlow() {
    debugger
    this.loansB = true;
    this.reports = false;
    this.name.GetLoansWithlowBalance().then(res => {
      this.LoansWithlowBalance = res
      debugger
      this.wait = false;
      this.LoansWithlowBalance.forEach(element => {
        element['name'] = this.listnames && this.listnames.find(a => a.MAINID == element.MAINID) ? this.listnames.find(a => a.MAINID == element.MAINID).FamilyName + '  ' + this.listnames.find(a => a.MAINID == element.MAINID).FirstName : null;
        element['CoinName'] = this.listCoins && this.listCoins.find(a => a.Id == element.Coin) ? this.listCoins.find(a => a.Id == element.Coin).Name : null;
        element['typeloanname'] = this.listTypes && this.listTypes.find(a => a.Id == element.typeloan) ? this.listTypes.find(a => a.Id == element.typeloan).Name : null;
      }
      )
      this.columnDefs = [
        { headerName: 'שם לווה', sortable: true, filter: "agTextColumnFilter", field: 'name', width: 180 },
        {
          headerName: 'תאריך לועזי', sortable: true, field: 'loanDateforien', filter: "agTextColumnFilter", width: 140, cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
          }
        },
        { headerName: 'תאריך עברי', field: 'loanDate', filter: "agTextColumnFilter", width: 140 },
        { headerName: 'סכום ', sortable: true, field: 'LoanAmount', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'יתרה', field: 'Balance', filter: "agNumberColumnFilter", width: 140 },
        { headerName: 'מטבע', sortable: true, field: 'CoinName', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'תשלומים', field: 'Payments', filter: "agTextColumnFilter", width: 90 },
        { headerName: 'הערה', field: 'notice', width: 300, filter: "agTextColumnFilter" },
        { headerName: 'סוג הלוואה', field: 'typeloanname', filter: "agTextColumnFilter", width: 140 },
        // {headerName: 'הערה', field: 'notice',filter: "agTextColumnFilter" ,width: 100},
      ];
      debugger
      this.list = this.LoansWithlowBalance;
      this.buildgrid();
    
       });
  }
  loanhigh()
{
  this.loansB=true;
  this.reports=false;
  debugger
  this.name.GetLoansWithhighBalance().then(res => {
    this.LoansWithhighwBalance = res
    this.wait=false;
    this.LoansWithhighwBalance.forEach(element => {
    element['name'] =this.listnames&&this.listnames.find(a => a.MAINID == element.MAINID)?this.listnames.find(a => a.MAINID == element.MAINID).FamilyName+'  '+this.listnames.find(a => a.MAINID == element.MAINID).FirstName:null;
    element['CoinName'] =this.listCoins&&this.listCoins.find(a => a.Id == element.Coin)?this.listCoins.find(a => a.Id == element.Coin).Name:null;
    element['typeloanname'] =this.listTypes&&this.listTypes.find(a => a.Id == element.typeloan)?this.listTypes.find(a => a.Id == element.typeloan).Name:null;
    }  
  )   
  this.columnDefs = [
    {headerName: 'שם לווה',sortable: true,filter: "agTextColumnFilter", field: 'name',width: 180},
    {headerName: 'תאריך לועזי',sortable: true, field: 'loanDateforien',filter:"agTextColumnFilter",width: 140,  cellRenderer: (data) => {
      return data.value ? (new Date(data.value)).toLocaleDateString() : '';}},
    {headerName: 'תאריך עברי', field: 'loanDate', filter: "agTextColumnFilter",width: 140},
    {headerName: 'סכום ' ,sortable: true, field: 'LoanAmount',filter: "agNumberColumnFilter",width: 140}, 
    {headerName: 'יתרה', field: 'Balance',filter: "agNumberColumnFilter",width: 140},
     {headerName: 'מטבע',sortable: true, field: 'CoinName',filter: "agTextColumnFilter",width: 90},
    {headerName: 'תשלומים', field: 'Payments',filter: "agTextColumnFilter",width: 90},
    {headerName: 'הערה', field: 'notice' ,width: 300,filter: "agTextColumnFilter"},
    {headerName: 'סוג הלוואה', field: 'typeloanname',filter: "agTextColumnFilter",width: 140},
    // {headerName: 'הערה', field: 'notice',filter: "agTextColumnFilter" ,width: 100},
  ];
this.list=this.LoansWithhighwBalance;
  this.buildgrid();

})
}
}
