import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { LoansService } from 'src/app/services/loans.service';
import { Route, Router, Params, ActivatedRoute } from '@angular/router';
import { Loans } from 'src/app/classes/loans';
import { NamesService } from 'src/app/services/names.service';
import { Names } from 'src/app/classes/names';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { Heczer } from 'src/app/classes/heczer';
import { Coin } from 'src/app/classes/coin';
import { HeczersComponent } from '../heczers/heczers.component';
import { HokHelpService } from 'src/app/services/hok-help.service';
import { TaskComponent } from '../task/task.component';
import { Contant } from 'src/app/classes/contant';
import { NeztigimService } from 'src/app/services/neztigim.service';
@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.css']
})
export class LoansComponent implements OnInit {
  //#region 
  id1;
  str: string
  items1: Names[];
  items3: Names[];
  items2: Names[];
  Contacts: Contant[];

  date: Date
  listfuture;
  listwas;
  listcomeback;
  num: number;
  num1: number = 0;
  namep;
  dateheczer;
  d: Date = new Date();
  num2: number = 0;
  LIST2;
  num3: number = 0;
  arev1;
  Arev3;
  Arev2;
  cell3: any;
  cell2: any;
  cell1: any;
  getbyway1: any;
  l: Loans = new Loans();
  value1: string;
  future1: boolean;
  PERMISSION: any;
  boolnew: boolean;
  iduser: any;
  Heczer = "Heczer"
  @ViewChild(HeczersComponent) appHeczersComponent: HeczersComponent;
  @ViewChild(TaskComponent) task: TaskComponent;
  idloan: number;
  degel1: boolean;
  dateh;
  loan1: Loans = new Loans();
  list;
  h: Heczer = new Heczer();
  listcoin: Coin[];
  listn;
  coin;
  items: Names[];
  b: boolean;
  bool;
  num5: number = 0;
  index1: any;
  listmerge: Array<number> = new Array<number>();
  listtypeloan: any;
  listshiabud: any;
  //#endregion
  constructor(private cd: ChangeDetectorRef, private route: ActivatedRoute, private name: NamesService,private netzigim:NeztigimService, private loan: LoansService, private help: HokHelpService, private router: Router, private maagar: MaagarMenageService) {
    this.route.params.subscribe((params: Params) => {
      this.PERMISSION = params.id;
      this.id1 = params.mainid;
      this.idloan = params.idloan
      this.iduser = params.iduser;
      if (params.index != null) {
        this.index1 = params.index;
        var x = this.index1;
        var index2: number = +x;
        this.num = index2;
        ;
      }
    })
  }
  ngOnInit() {
    this.date = new Date();
    this.viewnameslist();
this.showContacts();
    if (this.idloan != -1) {
      this.loan.GetTypesLoan().then(res => {
        this.listtypeloan = res
        this.showloans();
      });
    }
    else{
      this.addloan1();
    }
  }
  //#region show
  viewdetails(id: number) {

  }
  showContacts() {
    this.netzigim.Shownetzigim().then(res => {
      this.Contacts = res;
      this.Contacts.forEach(element => {

      element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] 
    
    });})
    }
  showhokdup(n: number) {
    this.num3 = this.list.length;
    if (this.num3 > 0) {
      if (this.num + n > 0 && this.num + n < this.num3) {
        if (this.num3 == 1) {

          this.l = this.list[0]
        }
        else {
          if (this.num + n == this.num3 - 1) {

            this.l = this.list[this.num3 - 1];
            this.num = this.num3 - 1;
            this.num5 = this.num3;
          }
          else {

            this.l = this.list[this.num + n]
            this.num += n;
            this.num5 = this.num + 1;
          }
        }
      }
      else {
        if (this.num + n <= 0) {

          this.l = this.list[0];
          this.num = 0;
          this.num5 = 1;
        }
      }
      this.boolnew = false;
      this.idloan = this.l.ID;
      this.task.viewhok(this.l.ID);
      ;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/loans1/" + this.iduser, this.PERMISSION, this.l.MAINID, this.l.ID, this.num]);
      })
    }
    else {

      this.num5 = 0;
      this.addloan1();
    }
  }

  showloans() {
    if (this.id1 != -1) {
      this.name.Showoans(this.id1).then(res => {
        this.list = res;
        if (this.list.length > 0 && this.idloan != -1) {
          this.num5 = this.num + 1;
          this.num3 = this.list.length;
          this.ShowLoan(this.idloan);

        }
      });
    }
    else {
      this.addloan1();
    }
  }

  ShowLoan(idloan: number) {

    this.l = this.list.find(a => a.ID == idloan);
    this.l.typename = this.listtypeloan && this.listtypeloan.find(a => a.Id == this.l.typeloan) ? this.listtypeloan.find(a => a.Id == this.l.typeloan).name : '';
    this.l.typename = this.l.typename.trim();
  }
  GetCoins() {
    this.listcoin = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (this.listcoin == null) {
      this.maagar.GetCoins().then(res => {
        this.listcoin = res;
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));
      })
      if (this.listcoin != null) {
        if (this.idloan != -1) {
          this.boolnew = false;
          this.getloan(this.idloan);
        }
        else {
          this.addloan1();
          this.items1 = this.items;
        }
      }
    }
  }
  viewnameslist() {
    this.items = JSON.parse(sessionStorage.getItem('ListNames'));
    this.items1=this.items
    debugger
    if (this.items == null) {
      this.name.ShowNames().then(res => {
        this.items = res;

        this.items.forEach(element => {
          if (element['CellPhone1'] != null && element['FirstName'] != null) {
            element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
          }
          else {
            if (element['CellPhone2'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
            }
            else {
              if (element['Phone'] != null && element['FirstName'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
              }
              else {
                if (element['CellPhone1'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
                }
                else {
                  if (element['CellPhone2'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                  }
                  else {
                    if (element['Phone'] != null) {
                      element['Name'] = element['FamilyName'] + " " + element['Phone']
                    }
                    else {
                      element['Name'] = element['FamilyName'] + " " + element['FirstName']
                    }
                  }
                }
              }
            }
          }
        })

      })
    }
    else{
      this.items.forEach(element => {
        if (element['CellPhone1'] != null && element['FirstName'] != null) {
          element['Name'] = "שם: " + element['FamilyName'] + " " + element['FirstName'] + " " + "פל': " + element['CellPhone1']
        }
        else {
          if (element['CellPhone2'] != null && element['FirstName'] != null) {
            element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['CellPhone2']
          }
          else {
            if (element['Phone'] != null && element['FirstName'] != null) {
              element['Name'] = element['FamilyName'] + " " + element['FirstName'] + " " + element['Phone']
            }
            else {
              if (element['CellPhone1'] != null) {
                element['Name'] = element['FamilyName'] + " " + element['CellPhone1']
              }
              else {
                if (element['CellPhone2'] != null) {
                  element['Name'] = element['FamilyName'] + " " + element['CellPhone2']
                }
                else {
                  if (element['Phone'] != null) {
                    element['Name'] = element['FamilyName'] + " " + element['Phone']
                  }
                  else {
                    element['Name'] = element['FamilyName'] + " " + element['FirstName']
                  }
                }
              }
            }
          }
        }
      })
    }
    this.items1=this.items
    this.GetCoins();

  }
  getloan(id: number) {
    this.loan.getloans(id).then(res => {
      this.l = res;
      this.dateh = this.l.loanDate;
      this.dateheczer = this.l.loanDateHeczer;
      LoansService.scum = this.l.LoanAmount;
    //  this.items1 = this.items;


    })
  }
  //#endregion
  //#region bool
  valuechange(value: string) {
    this.value1 = value;
    if (this.value1 == "דוח לחשבון הלוואה נוכחי") {
      this.loan.reports(false, this.idloan, this.future1);
    }
    else {
      if (this.value1 == "דוח לכל חשבונות ההלוואה") {
        this.loan.reports(true, this.idloan, this.future1);
      }
    }
  }
  gethok3(event) {
    this.Arev3 = event.MAINID;
    this.l.Arev1cell = event.CellPhone1;
    if (this.l.Arev1cell == null) {
      this.l.Arev1cell = event.CellPhone2;
    }
    if (this.l.Arev1cell == null) {
      this.l.Arev1cell = this.items.find(a => a.MAINID == this.l.Arev1).Phone;
    }
  }
  future() {
    this.future1 = !this.future1;
  }
  getbyway(v: string) {
    this.getbyway1 = v;
  }
  gethok1(event) {
    this.arev1 = event.MAINID;
    this.l.Arev1cell = event.CellPhone1;
    if (this.l.Arev1cell == null) {
      this.l.Arev1cell = event.CellPhone2;
    }
    if (this.l.Arev1cell == null) {
      this.l.Arev1cell = this.items.find(a => a.MAINID == this.l.Arev1).Phone;
    }
  }
  manege(event) {
    this.l.MAINIDMenege = event.MAINID;

  }
  Contactmanege(event) {
    this.l.MAINIDContact = event.MAINID;

  }
  gethok2(event) {
    this.Arev2 = event.MAINID;
    this.l.Arev2cell = event.CellPhone1;
    if (this.l.Arev2cell == null) {
      this.l.Arev2cell = event.CellPhone2;
    }
    if (this.l.Arev2cell == null) {
      this.l.Arev2cell = this.items.find(a => a.MAINID == this.l.Arev1).Phone;
    }
  }
  //#endregion
  //#region fun
  MergeLoans() {
   

    if (confirm("האם הינך בטוח שברצונך לאחד את כל הלוואות אלו עי פעולה זו יתכן שחלק מהנתונים ידרסו")) {
      ;
      this.list.forEach(element => {
        this.listmerge.push(element.ID)
      });
      this.loan.MergeLoans(this.listmerge);
    }
  }
  del() {
    this.loan.delLoan(this.l.ID).then(res => {
      ;
      if (!res) {
        alert("לא ניתן למחוק הלוואה עם החזרים");
      }
      else {
        this.showhokdup(this.index1)
      }
      ;
    })
  }
  AddToList(id: number) {
    this.listmerge.push(id);
  }
  addloan1() {
    ;
    this.l = new Loans();
    this.l.loanDateforien = this.date;
    this.l.MAINID = this.id1;
    this.l.Coin = 1;
    this.GetHebroDate()
    this.dateheczer = null;
    this.loan.addLoan(this.l).then(res => {
      this.idloan = res;
      this.l.ID = this.idloan;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/loans/" + this.iduser, this.PERMISSION, this.l.MAINID, this.l.ID]);
      });
    })
  }
  addLoan() {
    this.loan1.MAINID = this.id1;
    this.loan1.loanDate = this.dateh;
    this.loan1.loanDateforien = this.l.loanDateforien;
    this.loan1.LoanAmount = this.l.LoanAmount;
    this.loan1.Payments = this.l.Payments;
    this.loan1.Pay = this.l.Pay;
    this.loan1.Balance = this.l.Balance;
    this.loan1.Coin = this.coin;
    this.loan1.loanDateHeczer = this.dateheczer;
    this.loan1.loanDateforienHeczer = this.l.loanDateforienHeczer;
    this.loan1.Arev1 = this.arev1;
    this.loan1.MAINIDMenege = this.l.MAINIDMenege;
    this.loan1.MAINIDContact = this.l.MAINIDContact;

    this.loan1.typeloan = this.listtypeloan && this.listtypeloan.find(res => res.name.includes(this.l.typename)) ? this.listtypeloan.find(res => res.name.includes(this.l.typename)).Id : null;
    this.loan1.Arev2 = this.Arev2;
    this.loan1.Arev3 = this.Arev3;
    this.loan1.Arev1cell = this.l.Arev1cell;
    this.loan1.Arev2cell = this.l.Arev2cell
    this.loan1.Arev3cell = this.l.Arev3cell;
    this.loan1.notice = this.l.notice;
    this.loan1.UpdateBy = this.iduser;
    if (this.boolnew) {
      this.loan1.CreateBy = this.iduser;
      this.loan.addLoan(this.loan1).then(res => {
        if (res) {
          this.idloan = res;
          this.boolnew = false;
          this.router.navigate(["/loans/" + this.iduser, this.PERMISSION, this.id1, this.idloan]);
        }
      })
    }
    else {
      this.loan1.ID = this.idloan;
      this.loan.updateloan(this.loan1);
    }
  }
  ShiabudShow() {
    this.loan.ShowShiabud(this.l.ID).then(res => {
      ;
      this.listshiabud = res
    })
  }
  calculatedateheczer() {
    if (this.l.Payments == 1) {
      ;;
      this.date = new Date(this.l.loanDateforien.getFullYear(), this.l.loanDateforien.getMonth() + 6, this.l.loanDateforien.getDate());
      this.l.loanDateforienHeczer = this.date;
      this.GetHebroDate1();

    }
  }
  GetHebroDate() {
    this.help.GetHebroDate(this.l.loanDateforien).then(res => {
      this.dateh = res;
      ;;
    })

  }
  GetHebroDate1() {
    this.help.GetHebroDate(this.l.loanDateforienHeczer).then(res => {
      this.dateheczer = res;
      ;;
    })

  }
  heczer(id: number) {
    this.router.navigate(["/heczer"]);

  }
  coin1(val: string) {
    this.coin = this.listcoin.find(a => a.name == val).Id;
    
  }
  save(d: Date, n: string, scum: number) {
    this.h.MAINID = this.id1;
    this.h.Updateby = this.iduser;
    this.h.Createby = this.iduser;
    this.h.Scum = scum;
    this.h.Notice = n;
    this.h.StatusHeczer = "בוצע";
    ;
    this.h.IdLoan = this.idloan;
    this.h.TypeHok = this.getbyway1;
    this.loan.addheczer(this.h);
  }
  UpdateBalance() {
    this.l.Balance = this.l.LoanAmount - this.l.Pay;
  }
  //#endregion

}
