<table>
  <tr style="font-weight: bold;text-align: center;background-color: white !important;font-size: small;">
    הלוואות
  </tr>
  <tr>

    <td>
      <thead> סכום</thead>
    </td>
    <td>
      <thead> יתרה</thead>
    </td>
    <td>
      <thead>תאריך </thead>
    </td>
  </tr>

  <tr *ngFor='let item of list' (click)='viewdetails(item.ID,item.MAINID)' (dblclick)="viewdetails1(item.ID,item.MAINID)">


    <td>
      {{item.LoanAmount}}
    </td>
    <td>

      {{item.Balance}}
    </td>


    <td>

      {{item.loanDateforien|date:"dd/MM/yy"}}
    </td>

  </tr>
</table>
<app-showbalance *ngIf="HideTotal!=true" [loans]="loans" [loans1]="loans1" [hidden]="excel" class="col-2"
  style='position: absolute; top:0px;left:-580px;'></app-showbalance>
<app-arevtotalscum  *ngIf="HideTotal!=true" [hidden]="excel" class="col-2" style='position: absolute; top:0px;left:-830px;'></app-arevtotalscum>