import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NamesService } from '../services/names.service';

@Injectable({
  providedIn: 'root'
})
export class NeztigimService {

 

  UpdateName(nameafterupdate: any) {
    ;
    return this.http.post(NamesService.serverUrl +"/Neztigim/UpdateName", nameafterupdate).toPromise().then(
        response => {
          return true;
        })
      .catch(v => { return false; });
  }
  delete(mainid: any) {
    return this.http.get(NamesService.serverUrl +"/Neztigim/Delete?id=" + mainid).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }  

  addContact(n:any) {
    return this.http.post(NamesService.serverUrl +"/Neztigim/addContact",n).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });    }
    AddLeader(leadernew: any) :any{
      return this.http.post(NamesService.serverUrl +"/Neztigim/addLeader",leadernew).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });       }
  
  constructor(private http: HttpClient) { }
  Shownetzigim():any {
    return this.http.get(NamesService.serverUrl +"/Neztigim/GetNeztigim").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }  
    ShownetzigimL():any {
      return this.http.get(NamesService.serverUrl +"/Neztigim/GetNeztigimL").toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });  }
     
     
}
