<div style="height: 850px; overflow-y: auto;overflow-x: hidden;">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<app-main [iduser]="iduser" [user]="PERMISSION"></app-main>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<h3 style="position: relative;top:50px">view names</h3>
<label style="position: relative; top:120px;left:1716px;font-weight: bold;cursor: pointer;" >הצג רק הלוואות	&nbsp;
<input  type="checkbox" (click)="ShowJustloans()"></label>
<label style="position: relative; top:140px;left: 1570px;font-weight: bold;cursor: pointer;">הצג רק מפקידים	&nbsp;  
<input type="checkbox"  (click)="ShowJustmafkidim()"> </label>
<label style="position: relative; top:128px;left:1336px;font-weight: bold;cursor: pointer;"> {{num}} / {{length}}</label>
<ag-grid-angular 
    style="width:1420px; height: 350px;position: absolute; left:445px;top:230px;" 
    class="ag-theme-balham"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    [enableRtl]="true"
    [localeText]="localeText"
    [rowSelection]="rowSelection"
    (gridReady)="onGridReady($event)"
    (cellClicked)='viewdetails($event)' 
    (cellEditingStarted)='cellEditingStarted($event)'
    (cellEditingStopped)="cellEditingStopped($event)"
    [floatingFilter]="true">
</ag-grid-angular>
<!-- אופציות לבנא  -->
<div id="mySidenav"style="position: relative; 
top:50px;width:10px"  class="sidenav">
  <app-pniot  [mainid]="mainid" style="position: relative;left: 490px"></app-pniot>   

  <a   [hidden]="bool"  (click)="AddLoan()" id="contact">הוסף הלוואה</a>
  <app-add-waiting  [add]="true" [mainid]="mainid" style="position: relative;left: 490px;top:37px">  </app-add-waiting>
  <app-hok [iduser]="iduser" [mainid]="mainid" class="col-2" style="position: relative; left:490px;top:-46.5px"> </app-hok>
  <a  id="Tools1"  (click)=viewcalculator()>חישוב צפי</a>

  <a  id="Tools"  (click)=viewtools()>חישוב תכנית</a>
  <a  id="report"  onclick="document.getElementById('id09').style.display='block'">הדפס דו"ח לבנק</a>
  <a (click)="AddhafkadaCount()" id="about">חשבון הפקדה חדש</a>
<app-add-name [iduser]="iduser" [permission]="PERMISSION" class="col-2" style="position: relative; left: 485px;
top: -75px;
" title="הוספת שם"> </app-add-name>
 <img src="/assets/1/מעטפה.png" title="הצג מעטפה" (click)="PRINTENVELOPE()" style=" width: 48px; position: absolute; left: 439px; height: 52px; top:34px;   border-radius: 5px;" >
 <img src="/assets/1/folder.png" title="פתח תיקיה" (click)="openfolder()" style=" width: 48px; position: absolute; left: 495px; height: 52px; top:30px;   border-radius: 5px;" >
<i class="fa fa-trash"  style="font-size: 49px; position: absolute; left: 548px;  top: 30px;" title="מחק לקוח"  (click)="deletename()" ></i>
<svg [hidden]="DerugH" title="הצג דירוג" (click)="CalculateDerug()"width="1em" style="font-size: 40px; position: absolute; left: 658px;  top: 37px;"  height="1em" viewBox="0 0 16 16" class="bi bi-check-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<button [hidden]="!DerugH" title="הצג דירוג" style="font-size: 14px;
position: absolute;
left: 658px;
width: 40px;
top: 37px;
height: 40px;
border-radius: 75%;
background-color: rgb(55, 55, 55);color: white;"  height="1em" viewBox="0 0 16 16" class="bi bi-check-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
{{Derug}}</button>
</div>
<!-- //הצגת נתונים של בנ"א -->
<div style="width: 10px;" *ngIf='degel'  >
  <app-showhafkadotcount  [PERMISSION]="PERMISSION" [iduser]="iduser" [mainid]="mainid" class="col-2" style='position:absolute;top:400px;left: 1480px; '></app-showhafkadotcount>
  <br>
  <app-showloans  [user]="PERMISSION" [iduser]="iduser" class="col-2" style='position: absolute; top: 390px;left: 1230px;'></app-showloans>
  <app-showhok   [user]="PERMISSION" [iduser]="iduser" class="col-2" style='position: absolute; top:390px;left:990px;'></app-showhok>
  <!-- <app-showbalance class="col-2" style='position: absolute; top:520px;left:530px;'></app-showbalance> -->
</div>
<!-- הפקת דוחות -->
<div id="id09" class="modal"> 
  <form class="modal-content" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: relative;     left: -310px;
      top: -25px;" onclick="document.getElementById('id09').style.display='none'" class="close" title="Close Modal">&times;</span>  
    </div>
    <div class="container" >
<input type="checkbox" (click)="hafkada()"> הפקדות:
<!-- <input type="text" value="{{}}"> -->
<input type="checkbox" (click)="halvaa()"> הלוואות:
<!-- <input type="text" value="{{}}"> -->
       <button (click)="report()" onclick="document.getElementById('id09').style.display='none'" style="position: relative; top: -40px; left: 65px;"  class="btn">שלח</button>    
       <!-- <button  type="button" onclick="document.getElementById('id01').style.display='none'"  class="button button1" (click)='addhafkadot(ID.value,IdHok.value,mis.value,Amount.value,DateForiegnHAfkada.value,Amla.value)'> הכנס הפקדה </button>
            <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn">ביטול</button> -->
    </div>
  </form>
</div>
<app-showname *ngIf="mainid!=null" [id]="mainid" [name]="true" class="col-2" style='position: absolute; top:130px;left:75px;'></app-showname>  
</div>

<router-outlet></router-outlet>