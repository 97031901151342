import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { NeztigimService } from 'src/app/services/neztigim.service';
// import "ag-grid-enterprise";
import { Contant } from 'src/app/classes/contant';
// import { Content } from '@angular/compiler/src/render3/r3_ast';
// import { Constants } from 'ag-grid-community';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { ShowhafkadotcountComponent } from '../showhafkadotcount/showhafkadotcount.component';
import { LOCALE_TEXT } from 'src/app/classes/constance';
import { ShowloansComponent } from '../showloans/showloans.component';

@Component({
  selector: 'app-netzigim',
  templateUrl: './netzigim.component.html',
  styleUrls: ['./netzigim.component.css']
})
export class NetzigimComponent implements OnInit {
  //#region 
  columnDefs;
list;
@ViewChild(ShowhafkadotcountComponent)app:ShowhafkadotcountComponent;
@ViewChild(ShowloansComponent)apploan:ShowloansComponent;

list2;
listL;
listnames;
rowData;
gridOptions;
mainid;
  nameupdate: Contant=new Contant();
  cityBefor: any;
  nameafterupdate: Contant=new Contant();
  listNemesCity: Array<string>=new Array<string>();
  listG: any;
  listl1: any;
  rowSelection: string;
  localeText: any;
//#endregion
  constructor(private netzigim:NeztigimService,private city:MaagarMenageService,private cd: ChangeDetectorRef) { }
    @Input() PERMISSION;
    @Input()iduser;
    ngOnInit() {
    this.showcities();
    this.showGroups();
    this.ShowLeaders();
    this.columnDefs = [
      {headerName: 'קוד', field: 'MAINID' ,width: 30},
      {headerName: 'תואר', field: 'Toar',width: 70,editable: true,filter: "agTextColumnFilter"},
      {headerName: 'משפחה', field: 'FamilyName',sortable: true,sort: 'asc' ,width: 100,filter: "agTextColumnFilter",editable: true},
      {headerName: 'פרטי', field: 'FirstName',sortable: true ,filter: "agTextColumnFilter",width: 100,editable: true},
      {headerName: 'טלפון', field: 'Phone',width: 100,editable: true,filter: "agTextColumnFilter"},
      {headerName: 'פלאפון 1', field: 'CellPhone1',width: 100,editable: true,filter: "agTextColumnFilter"},
      {headerName: ' פלאפון2' , field: 'CellPhone2',filter: "agTextColumnFilter",width: 100,editable: true},
      {headerName: 'כתובת', field: 'Adress',filter: "agTextColumnFilter",width: 100,editable: true},
      {headerName: 'עיר', field: 'cityName',width: 100,editable: true,filter: "agTextColumnFilter" , cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: this.listNemesCity
      }},
      {headerName: 'אחראי אזור', field: 'ariaName',width: 100,editable: true,filter: "agTextColumnFilter", cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: this.listNemesCity
      }},
      {headerName: 'מייל', field: 'Email',filter: "agTextColumnFilter",width: 150,editable: true},
      {headerName: 'פקס', field: 'Fax',width: 100,editable: true,filter: "agTextColumnFilter"},
      {headerName: 'שלח טפסים באמצעות', field: 'senddocumentsto',filter: "agTextColumnFilter",editable: true},
      {headerName: 'מס צרופים', field: 'NumOfJoiners',filter: "agTextColumnFilter" ,width: 100,editable: true},
      {headerName: 'מס יחידות', field: 'NumOfYecidot',filter: "agTextColumnFilter" ,width: 100},
      {headerName: 'חוב', field: 'chov', filter: "agTextColumnFilter" ,width: 100}
  ];
  }
  showGroups() {
    this.city.GetGroups().then(res => {
      this.listG = res;} )
  }
  showcities() {
  this.city.ShowCity().then(res => {
    this.listnames = res; 
    this.listnames.forEach(element => {
      this.listNemesCity.push( element.NameCity)  })   
        this.showContacts();
  });
}
deletenatzig() {
  if (this.mainid != null) {
    alert("האם אתה בטוח שברצונך למחוק?");
    this.netzigim.delete(this.mainid).then(res => {
      if (!res) {
        alert("לא ניתן למחוק");
      }
      else {
        this.showContacts();
      }
    })
  }
  else {
    alert("לא נבחר שם");
  }
}
  refreshlist(event)
  {
    this.list=this.list2.filter(t=>t.leader==event.MAINID);
    this.rowData=this.list;
    this.buildgrid();
  }
  refreshlist1(event)
  {

    this.listl1=this.listL.filter(a=>a.group==event.Id);
    this.list=this.list2.filter(t=>t.leader==this.listl1[0].MAINID);
    this.rowData=this.list;
    this.buildgrid();
  }
  viewdetails(event)
  {
    debugger;

    this.mainid=event.data.MAINID;
    this.app.showhafkadottocontact(event.data.MAINID);
    this.cd.detectChanges();
    debugger;
    this.apploan.showloansToContact(event.data.MAINID);
    this.cd.detectChanges();
  }
  ShowLeaders() {
    this.netzigim.ShownetzigimL().then(res => {
      this.listL = res;
      this.listl1=this.listL;

 });}
 cellEditingStarted(event)
  {
    this.nameupdate=event.data;
    this.cityBefor=event.data.cityName;
  }
  cellEditingStopped(event)
  {
    this.nameafterupdate.MAINID=event.data.MAINID;
    this.nameafterupdate.FamilyName=event.data.FamilyName;
    this.nameafterupdate.FirstName=event.data.FirstName;
    this.nameafterupdate.CellPhone1=event.data.CellPhone1;
    this.nameafterupdate.CellPhone2=event.data.CellPhone2;
    this.nameafterupdate.Phone=event.data.Phone;
    this.nameafterupdate.Adress=event.data.Adress;
    this.nameafterupdate.Email=event.data.Email;
    this.nameafterupdate.Remarks=event.data.Remarks;
    this.nameafterupdate.Toar=event.data.Toar;
    this.nameafterupdate.City=event.data.City;
    // this.nameafterupdate.MAINID=event.data.MAINID;
    this.nameafterupdate.Mikud=event.data.Mikud;
    this.nameafterupdate.Fax=event.data.Fax;
    this.nameafterupdate.chov=event.data.chov;
    this.nameafterupdate.leader=event.data.leader;
    if(this.cityBefor!=event.data.cityName)
    {
      this.nameafterupdate.City=this.listnames.find(a=>a.NameCity==event.data.cityName).CityId;
      ;
    }
this.netzigim.UpdateName(this.nameafterupdate);
  }
  buildgrid()
   {
    this.localeText = LOCALE_TEXT,
    this.rowSelection= 'single',
    this.rowData = this.list;
     this. columnDefs= this.columnDefs }  

  showContacts() {
    this.netzigim.Shownetzigim().then(res => {
      this.list = res;
      this.list2=this.list;

      this.list.forEach(element => {
        element['cityName'] =this.listnames && this.listnames.find(a => a.CityId == element.City)?this.listnames.find(a => a.CityId == element.City).NameCity:'';
        element['ariaName'] =this.listnames && this.listnames.find(a => a.CityId == element.aria)?this.listnames.find(a => a.CityId == element.aria).NameCity:'';

        this.rowData=this.list;
 });
    })
    this.buildgrid();

  }
}
