import { Component, OnInit } from '@angular/core';
import { Contant } from 'src/app/classes/contant';
import { NeztigimService } from 'src/app/services/neztigim.service';
import { NamesService } from 'src/app/services/names.service';
import { Names } from 'src/app/classes/names';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { Leader } from 'src/app/classes/leader';
@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  //#region 
  kod: any;
  idl;
  n:Contant= new Contant;
  list;
  listL: any;
  listN;
  kod1: any;
  exist: boolean;
  lead: boolean;
  listG;
  leadernew :Leader=new Leader();
  idg: any;
  n2: Names=new Names();
  //#endregion
  constructor(private netzigim:NeztigimService,private name:NamesService,private cityid:MaagarMenageService) { }
  ngOnInit() { 
     this.showleaders();
     this.ShowCities();
     this.ShowGroups();
     this.showNames();
     this.n2.FamilyName="";
  }
  //#region  show

  ShowGroups() {
    this.cityid.GetGroups().then(res => {
      this.listG = res;  })} 
  showNames() {
    this.listN = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.listN == null) {
    this.name.ShowNames().then(res => {
      this.listN = res;  
      sessionStorage.setItem('ListNames', JSON.stringify(this.list));
  })  }
    if(this.listN!=null)
    {
      this.listN.forEach(element => {  
        element['Name'] ="שם: "+ element['FamilyName']+" "+element['FirstName']
      }) } 
    }
  showleaders() {
    this.netzigim.ShownetzigimL().then(res => {
      this.listL = res;  })}

  ShowCities()
  {
    this.cityid.ShowCity().then(res=>{
      this.list=res;})
  }
  //#endregion
  //#region  bool  
  SelectCity(status:string)
  {
    debugger;
    this.kod=this.list.find(a=>a.NameCity==status)? this.list.find(a=>a.NameCity==status).CityId:'';

  }   
  save(event)
{
  this.n2= event;
this.exist=true;
this.n2['namecity']=this.list.find(a=>a.CityId==this.n2.City)? this.list.find(a=>a.CityId==this.n2.City).NameCity:'';
}

  refreshlist(event)//save leader
  {
this.idl=event.MAINID;
  }
  leader()
  {
    this.lead=true;
  }
  SelectCity1(status:string)
  {
    this.kod1=this.list.find(a=>a.NameCity==status)? this.list.find(a=>a.NameCity==status).CityId:'';
  } 
   SelectGroup(g:string)
{
this.idg= this.listG&this.listG.find(a=>a.name==g)?this.listG.find(a=>a.name==g).Id:'';
  }
 
  //#endregion
  addName() {
    // if(this.n2==null)
    // {  
    //  this.n.ID=idp;
    //  this.n.Toar=toar;
    //  this.n.FirstName=FirstName;
    // this.n.FamilyName=LastName;
    //  this.n.Phone=Phone;
    //  this.n.CellPhone1=Phone1;
    //  this.n.CellPhone2=Phone2;
    //  this.n.Email=Mail;
    //  this.n.Adress=Adress;
    //  this.n.Fax=fax;
    //  this.n.Mikud=mikud;
    //  this.n.leader=this.idl;
    //  this.n.Remarks=remark;
    //  this.n.City= this.kod;
    //  this.n.aria=this.kod1;}
    //  else{
        this.n.ID=this.n2.ID;
      this.n.Toar=this.n2.Toar;
      this.n.MAINID=this.n2.MAINID;

      this.n.FirstName=this.n2.FirstName;
     this.n.FamilyName=this.n2.FamilyName;
      this.n.Phone=this.n2.Phone;
      this.n.CellPhone1=this.n2.CellPhone1;
      this.n.CellPhone2=this.n2.CellPhone2;
      this.n.Email=this.n2.Email;
      this.n.Adress=this.n2.Adress;
      this.n.Fax=this.n2.Fax;
      this.n.Mikud=this.n2.Mikud;
      this.n.leader=this.idl;
      this.n.Remarks=this.n2.Remarks;
      this.n.City= this.kod;
      debugger;
      this.n.aria=this.kod1;
      // מוביל
     if(this.lead)
     {
      this.leadernew.MAINID=this.n2.MAINID;
       this.leadernew.group=this.idg;
       this.leadernew.ID=this.n2.ID;
       this.leadernew.Toar=this.n2.Toar;
       this.leadernew.FirstName=this.n2.FirstName;
      this.leadernew.FamilyName=this.n2.FamilyName;
       this.leadernew.Phone=this.n2.Phone;
       this.leadernew.CellPhone1=this.n2.CellPhone1;
       this.leadernew.CellPhone2=this.n2.CellPhone2;
       this.leadernew.Email=this.n2.Email;
       this.leadernew.Adress=this.n2.Adress;
       this.leadernew.Fax=this.n2.Fax;
       this.leadernew.Mikud=this.n2.Mikud;
       this.leadernew.Remarks=this.n2.Remarks;
       this.leadernew.City= this.kod;
      
   this.netzigim.AddLeader(this.leadernew).then(res=>{
   this.n.leader=res;})
     }
 
     this.netzigim.addContact(this.n);  }                
    }

