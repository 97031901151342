import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Names } from '../classes/names';
// import { ID_SEPARATOR } from '@angular/compiler/src/render3/view/util';

@Injectable({
  providedIn: 'root'
})
export class NamesService {


  

 
 public static id;
  public static serverUrl="api/"////"http://localhost:63322/api";"api"//
  static mainid: any;
  public static list: any;
  constructor(private http: HttpClient) { }
  existid(ID: string) :any{
    return this.http.get(NamesService.serverUrl +"/Names/existid?id="+ID).toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; }); }
    CalculateDerug(mainid: any) 
      :any{
        return this.http.get(NamesService.serverUrl +"/Names/CalculateDerug?id="+mainid).toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });    }
        ShowoansToContact(id: number) {
 
          return this.http.get(NamesService.serverUrl +"/Loans/GetAcountsByContact?id="+id).toPromise().then(
                    
            response => {
             return  response;
            })
          .catch(v => { return false; }); }
    ShowLoans():any {
      return this.http.get(NamesService.serverUrl +"/Loans/GetLoans").toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });       }
      GetLoansWithByConcat() :any {
        return this.http.get(NamesService.serverUrl +"/Loans/LoansByContact").toPromise().then(
          response => {
           return  response;
          })      }
      GetLoansWithlowBalance() :any{
        return this.http.get(NamesService.serverUrl +"/Loans/GetLoansWithLowHeczers").toPromise().then(
          response => {
           return  response;
          })
    }      
GetLoansWithhighBalance() :any{
      return this.http.get(NamesService.serverUrl +"/Loans/GetLoansWithHighHeczers").toPromise().then(
        response => {
         return  response;
        })
  }        
  GetPniot(mainid:any) :any{
    return this.http.get(NamesService.serverUrl +"/Names/GetPniot?id="+mainid).toPromise().then(
      response => {
       return  response;
      })
}        
      GetLoansWithWorngBalance():any {
        return this.http.get(NamesService.serverUrl +"/Loans/GetLoansWithWorngBalance").toPromise().then(
          response => {
           return  response;
          })
        .catch(v => { return false; });       }
   ShowNameswithhok():any {
    return this.http.get(NamesService.serverUrl +"/Names/ShowNameswithhok").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });   
  } 
   ShowNameswithmail():any{
    return this.http.get(NamesService.serverUrl +"/Names/ShowNameswitemail").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });   
  }
  openfolder(id: any) {      ;

    return this.http.get(NamesService.serverUrl +"/Names/openfolder?id="+id) .toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
    // GetNameByidmafkid(MafkidID: any): any {
    //   return this.http.get(NamesService.serverUrl +"/Names/GetNameByidmafkid?id="+MafkidID) .toPromise().then(
    //     response => {
    //      return  response;  })  }
    // }
    
    PRINTENVELOPE(mainid: any) {
      return this.http.get(NamesService.serverUrl +"/Names/PRINTENVELOPE?id="+mainid) .toPromise().then(
        response => {
         return  response;  })  }
    
    delete(id: number) :any{
      return this.http.delete(NamesService.serverUrl +"/Names/Delete?id="+id) .toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });  }
   
  bankreports(id: any) {
    ;
    return this.http.get(NamesService.serverUrl +"/Names/bankreports?id="+id) .toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
 ShowNamesmafkidim():any {
  return this.http.get(NamesService.serverUrl +"/Names/ShowJustmafkidim").toPromise().then(
    response => {
     return  response;
    })
  .catch(v => { return false; });  }
  showbalance(id: any) {
    return this.http.get(NamesService.serverUrl +"/Names/showbalance").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  
  ShowNamesLoans():any {
    return this.http.get(NamesService.serverUrl +"/Names/ShowJustloans").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });  }
  ShowBoth():any {
    return this.http.get(NamesService.serverUrl +"/Names/Showboth").toPromise().then(
      response => {
       return  response;
      })
    .catch(v => { return false; });   }
    ShowNames(): any {
      return this.http.get(NamesService.serverUrl +"/Names").toPromise().then(
        response => {
        NamesService.list=response;
        ;
         return  response;
        })
      
      .catch(v => { return false; });
      
    }
    UpdateName(nameafterupdate: Names) {
      ;
      return this.http.post(NamesService.serverUrl +"/Names/UpdateName", nameafterupdate).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; });
    }
    
    ShowNameslist(): any {
      return this.http.get(NamesService.serverUrl +"/Names/GetNameslist").toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
    
  
    Showoans(id:number): any {
      return this.http.get(NamesService.serverUrl +"/Loans/GetLoansByName?id="+id).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
    
    addName(pro:Names): any {
      return this.http.post(NamesService.serverUrl +"/Names/PostName", pro).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; });
    }
    AddPniot(pro:any): any {
      return this.http.post(NamesService.serverUrl +"/Names/PostPniot", pro).toPromise().then(
          response => {
            return true;
          })
        .catch(v => { return false; });
    }
    GetName(id:number): any {
      return this.http.get(NamesService.serverUrl +"/Names/GetName?id="+id
     ).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    }
  
    filter(datestart:Date, dateend:Date,want:string,want1:string,scum:number,kod:number): any {
      return this.http.put(NamesService.serverUrl +"/Names/FilterName",{d:datestart, d1:dateend, w:want,  w1:want1,s:scum,kodcat:kod}).toPromise().then(
        response => {
         return  response;
        })
      .catch(v => { return false; });
    
    }
}