<div id="table-scroll">

<table>
  <tr style="font-weight: bold;text-align: center;background-color: white !important">
    הפקדות
  </tr>
  <tr class="tr tr1 ">

    <td class="shrink">
      <thead> סכום</thead>
    </td>
    <td class="shrink">
      <thead> תרומה</thead>
    </td>
    <td *ngIf="fromContact==true" class="expand" >
      <thead> שם</thead>
    </td>
    <td class="expand" >
      <thead> הערה</thead>
    </td>
  </tr>

  <tr *ngFor='let item of list' (click)='viewdetails(item.Id)' >

    <td class="shrink">
      {{item.Totalhafkada}}
    </td>
    <td class="shrink">
      {{item.Totaltruma}}
    </td>
    <td  *ngIf="fromContact==true" class="shrink"  >
      {{item.Name}}
    </td>
    <td class="shrink" >
      {{item.Remark  | slice:0:40}}

    </td>

  </tr>
</table>
</div>