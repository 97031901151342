    <img style="width: 50px;height: 50px;" src="/assets/1/person.png"onclick="document.getElementById('id05').style.display='block'"  >
    <div id="id05" class="modal1" style="display: none">
      <span style=" position: relative; left:-675px" onclick="document.getElementById('id05').style.display='none'" class="close" title="סגור">&times;</span>
      <form class="modal-content1" action="/action_page.php">
        <div class="container" style="text-align: center">
          <div class="row"> 
      <div class="titel">
          <h4 style="position: relative;top:33px">הוסף נציג</h4>
          <br>
          <br> 
          <p  style="position: relative;left: -61px; top: 57px;"> בחר נציג קיים </p> 
          <ng-select  style="width:266%;height: 32px; position: relative; left: -110px; top: 41px; direction: rtl; background-color: gray; color: white;border-radius: 4px;" 
          (change)='save($event)' [items]="listN" bindValue="MAINID" #val bindLabel="Name"   >
          </ng-select>
         <p style="position: relative;left: -20px; top: 116px;"> תז </p> 
        <input type=" text" class=" form-control"  style="position: relative;left: -110px;top: 100px;" [(ngModel)]="n2.ID" [ngModelOptions]="{standalone: true}" #ID >  
        <br>
          <br>
          <p  style="position: relative;left: -367px; top: -5px;"> תואר</p>
          <input  [(ngModel)]="n2.Toar" [ngModelOptions]="{standalone: true}" type=" text" class=" form-control"  style="position: relative;left: -110px;top: 31px;"  style="position: relative;left: -450px;top: -22px;"  #Toar>
          <p style="position: relative;left: -43px; top: 47px;"> שם פרטי</p>
          <input  type=" text" [(ngModel)]="n2.FirstName" [ngModelOptions]="{standalone: true}"  class=" form-control"  style="position: relative;left: -110px;top: 31px;"  #Fname>
          <p style="position: relative;left: -392px; top: -26px;"> שם משפחה</p>
          <input type=" text"  [(ngModel)]="n2.FamilyName" [ngModelOptions]="{standalone: true}" class=" form-control"   style="position: relative;left: -450px;top: -43px;"  #Lname>
          <p style="position: relative;left: -59px; top: 26px;"> מס' פלאפון  1 </p>
          <input type=" text" [(ngModel)]="n2.CellPhone1" [ngModelOptions]="{standalone: true}"  class=" form-control"  style="position: relative;left: -110px;top: 11px;"  #Phone1>
          <p style="position: relative;left: -398px; top: -47px;">מס' פלאפון  2</p>
          <input  type="text"  [(ngModel)]="n2.CellPhone2" [ngModelOptions]="{standalone: true}"class=" form-control"  style="position: relative;left: -450px;top: -63px;"  #Phone2>
          <p  style="position: relative;left: -47px;  top: 5px;"> מס' טלפון</p>
          <input type="text" [(ngModel)]="n2.Phone" [ngModelOptions]="{standalone: true}" class=" form-control" style="position: relative;left: -110px;top: -10px;" #TEL>
          <p style="position: relative;left: -366px; top: -69px;"> פקס</p>
          <input [(ngModel)]="n2.Fax" [ngModelOptions]="{standalone: true}" type=" text" class=" form-control" style="position: relative;left: -450px;top: -84px;"  #Fax>
          <p  style="position: relative;left: -25px; top: -14px;"> מייל</p>
          <input  type="text" [(ngModel)]="n2.Email" [ngModelOptions]="{standalone: true}" class=" form-control" style="position: relative;left: -110px;top: -30px;"   #Email>
      
          <p style="position: relative;left: -359px; top: -92px;"> עיר</p>
          <select   class="custom-select" style="position: relative;left: -446px; top: -107px;"  #City (change)='SelectCity(City.value)' >
            <option   *ngFor='let status of list'>{{status.NameCity}}</option> </select>
            <p style="position: relative;left: -388px; top: 266px;">  אחראי עיר</p>
         <select    class="custom-select" style="position: relative;left: -450px; top: 250px;"  #City1 (change)='SelectCity1(City1.value)' >
            <option   *ngFor='let status of list'>{{status.NameCity}}</option> 
          </select>
          <p style="position: relative;left: -34px; top: -106px;"> כתובת </p>
          <input type="text"  class=" form-control" [(ngModel)]="n2.Adress" [ngModelOptions]="{standalone: true}" style="position: relative;left: -110px; top: -122px;" #Adress>
          <p style="position: relative; left: -369px; top: -180px;"> מיקוד </p>
          <input type="text" class=" form-control"[(ngModel)]="n2.Mikud" [ngModelOptions]="{standalone: true}" style="position: relative; left: -450px; top: -197px;" #Mikud>
          <p style="position: relative; left: -34px;top: -109px;"> הערות </p>
          <input type="text" class=" form-control" [(ngModel)]="n2.Remarks" [ngModelOptions]="{standalone: true}"  style="position: relative; left: -110px; top: -124px;"  #Remarks>
        
          <p style="position: relative;left: -392px; top: -184px;"> שלח טופס ל: </p>
          <input type="text" value="{{n2.senddocumentsto}}" class=" form-control" style="position: relative;left: -450px; top: -200px;"  #senddocumentsto>
          <p style="position: absolute; left: 540px; top: 1240px;">  נציג אחראי </p>
          <ng-select  style="width:204px;height: 33px; position: relative; left: -112px; top: -88px; direction: rtl; background-color: gray; color: white;border-radius: 4px;" 
          (change)='refreshlist($event)' [items]="listL" bindValue="MAINID" #val bindLabel="FamilyName"   >
          </ng-select>
      <label style="position: absolute; top: 1394px; left: 617px;"><p style="position: relative;left: -84px; top: 14px;"> אחראי קבוצה? </p>
<input type="checkbox" (click)='leader()' style="position: absolute; top: 18px; left: 29px; width: 16px; height: 16px;">
</label>
<p [hidden]="!lead" style="position: relative; left: -396px; top: -6px;"> שייך לקבוצה</p>
<select [hidden]="!lead" class="custom-select" style="position: relative;left: -450px; top: -22px;"  #g (change)='SelectGroup(g.value)' >
  <option   *ngFor='let status of listG'>{{status.name}}</option> </select>
          <div class="clearfix">
            <button type="button" onclick="document.getElementById('id05').style.display='none'"  style="position: absolute; left: 529px;top: 1462px; width: 136px;" class="cancelbtn">ביטול</button>
            <button  type="submit" onclick="document.getElementById('id05').style.display='none'" class="ok"  style="position: absolute; left: 118px; top: 1460px;" (click)='addName()'>אישור</button> 
          </div>

          </div>
        </div>
        </div>
       </form>
    </div>
    
  

