<div style="height: 850px;width: 2000px;overflow-x: hidden; overflow-y: auto;;position: relative;">

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<app-add-contact class="col-2" style='position:absolute;top:260px;left: 440px; '></app-add-contact>

<button   style=" position: absolute; left: 548px;background-color: red; top: 270px;"  (click)="deletenatzig()" >מחק נציג</button>

<h4 style="position: absolute; left: 1010px; top: 158px; font-size: 34px;">רשימת נציגים</h4>
<h6 style="width:20px;height: 32px; position: absolute;left: 1700px; top: 258px;" >אחראי</h6>
    <ng-select  style="width: 200px;height: 32px;position: absolute; left: 1550px; top: 280px; direction: rtl; background-color: gray; color: white;border-radius: 4px;" 
        (change)='refreshlist($event)' [items]="listl1" bindValue="MAINID" bindLabel="FamilyName"   >
        </ng-select> 
        <h6 style="width:20px;height: 32px; position: absolute; left: 1477px; top: 258px;" >קבוצה</h6>
    <ng-select  style="width: 200px;height: 32px; position: absolute;  left: 1327px; top: 280px; direction: rtl; background-color: gray; color: white;border-radius: 4px;" 
     (change)='refreshlist1($event)' [items]="listG" bindValue="id"  bindLabel="name"   >
        </ng-select> 
<ag-grid-angular 
    style="width:1300px; height: 350px;position: absolute; left:450px;top:332px;" 
    class="ag-theme-balham"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    [rowSelection]="rowSelection"
    [localeText]="localeText" 
    (cellClicked)='viewdetails($event)'
    [enableRtl]="true"
    (cellEditingStarted)='cellEditingStarted($event)'
    (cellEditingStopped)="cellEditingStopped($event)"
    [floatingFilter]="true">
</ag-grid-angular>
<app-showhafkadotcount [fromContact]="true" [PERMISSION]="PERMISSION" [iduser]="iduser" [mainid]="mainid" class="col-2" style='position:absolute;top:640px;left: 1520px; '></app-showhafkadotcount>
<app-showloans [HideTotal]="true" [user]="PERMISSION" [iduser]="iduser" class="col-2" style='position: absolute; top: 640px;left: 1130px;'></app-showloans>
</div>