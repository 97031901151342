<meta name="viewport" content="width=device-width, initial-scale=1.0" >
<div style="height:978px;overflow: hidden;">
<app-main  [user]="PERMISSION" [iduser]="iduser" ></app-main>
<h6>Loan - Account</h6>
    <img style="position: relative;left: 878px; top: 122px; block-size: 49px;cursor: pointer; "
  onclick="document.getElementById('id022').style.display='block'" title="דוחות לשליחה"  src="/assets/1/send.png" >
  <img src="/assets/1/file.png" (click)="MergeLoans()"  style="position: relative;
    height: 50px;
    top: 122px;
    left: 925px;
    width: 60px;">
  <img src="/assets/1/l.png" (click)="ShiabudShow()"  style="position: relative;
  height: 50px;
  top: 122px;
  left: 925px;
  width: 60px;">
<div id="id022" class="modal">
  <form class="modal-content animate" action="/action_page.php">
    <div class="imgcontainer">
      <span style="position: absolute;  left: 11px; top: -25px;font-size: 2.1rem; cursor: pointer;"
        onclick="document.getElementById('id022').style.display='none'" class="close" title="סגור">&times;</span>
    </div>   
    <div class="container1" >
      <div style="position: absolute;left: 8rem;">
       <label style="position: relative; left: -45px;  top: 45px; font-size: 15px;" [hidden]="boolnew"  (click)="future()"> <input  type="checkbox" style=" position: absolute; left: 143px; top: 5px; width: 13px; height: 13px; font-weight: bold;" (click)="future()" [hidden]="boolnew" >כולל החזרים עתידיים</label>
        <p style="position: absolute; left: -58px; top: -21px; direction: rtl ;text-align: right; height: 32px;font-size: 18px;"> <b>בחר דוחות לשליחה:</b></p>
        <select  #select  style="position: absolute;border: solid 1px lightgrey;  left: -85px; top: 9px; text-align: center; height: 32px; width: 199px;border-radius: 4px; ">
          <option >דוח לכל חשבונות ההלוואה</option>
          <option >דוח לחשבון הלוואה נוכחי</option>
            </select>  
        <button  class="bb" style= " position: absolute;left: -83px;  top: 71px; height: 31px;   color: white; width: 198px !important; border: solid 1px black; border-radius: 4px;padding: 4px ;"(click)='valuechange(select.value)'  class="next" id="b1">שלח</button>
        </div>
    </div>
  </form>
</div>
  
    <div  class="col-12">
        <div class="row" style="height: 10px;" >
          <div class="container" style="position: absolute;top: 72px; left: 35px;  height: 700px;border-radius: 9px;">
              <div class="row">
                <div class="col-50">
                  <div style="position: relative;top: -45px; left: -2px;">
                  <input [(ngModel)]="num5"  disabled [ngModelOptions]="{standalone: true}" ngDefaultControl name="something1" style="background-color:#a1a1a1;border:none;width: 30px;
                  position: relative;left: -411px;  top: 84px; font-weight: bolder;">   
         
                  <label style="position: absolute;left: 125px; top:89px; font-size: 114%; font-weight: bolder;z-index: 1;">/</label>
                  <input [(ngModel)]="num3"  disabled [ngModelOptions]="{standalone: true}" ngDefaultControl  name="something" style="background-color: #a1a1a1 ;border: none;width: 30px;
                  position: relative; left: -345px; top: 84px; font-weight: bolder; ">
                    <svg  (click)="del()" style="position: absolute;left:40px;top: 56px;width: 32px;height:40px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                    </svg>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" (click)="showhokdup(1)"  style="position: absolute;left:135px;top: 59px;width: 28px;height: 35px;" class="bi bi-caret-right-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z"/>
                    </svg>
                    <svg width="1em" height="1em" (click)="showhokdup(-1)"viewBox="0 0 16 16"  style="position: absolute;left:104px;top: 59px;width: 28px;height: 35px;" class="bi bi-caret-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.5 10a.5.5 0 0 1-.832.374l-4.5-4a.5.5 0 0 1 0-.748l4.5-4A.5.5 0 0 1 10.5 4v8z"/>
                    </svg>
                    <svg  (click)="addloan1()" style="position: absolute;left:70px;top: 56px;width: 32px;height: 40px;" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                      <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
                      <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    </svg>
                    <h3 style="position: relative; left:-214px;top:25px;font-size: 1.9rem;"> הלוואה</h3>
                    <br>     
                    <div style="display: flex;">
                    <input type="date" (change)='GetHebroDate()'  (ngModelChange)="l.loanDateforien= $event"  [ngModel]="l.loanDateforien| date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"ngDefaultControl  class="DateforienLoan" placeholder="תאריך לועזי"   #DateforienLoan>
                    <!-- <p >תאריך הלוואה</p> -->
                    <input type="text" class="DateLoan"    [(ngModel)]="dateh"  [ngModelOptions]="{standalone: true}"   placeholder="תאריך הלוואה"  #DateLoan>
                  </div>
                  <div style="display: flex;">

                    <!-- value="{{dateh}}" -->
                    <!-- <p>סכום הלוואה</p> -->
                    <input type="number" class="LoanAmount"  [(ngModel)]="l.LoanAmount" [ngModelOptions]="{standalone: true}"  (change)="UpdateBalance()"  placeholder="סכום הלוואה"  step="1000"  #LoanAmount>
                    <!-- <p>מספר תשלומים</p> -->
                    <input type="number" class="Payments" (change)="calculatedateheczer()" [(ngModel)]="l.Payments" [ngModelOptions]="{standalone: true}"  placeholder="מספר תשלומים"   #Payments>
                    <!-- <p>סכום ששולם</p> -->
                  </div>
                  <div style="display: flex;">

                    <input type="number" class="Pay" [(ngModel)]="l.Pay" [ngModelOptions]="{standalone: true}"  title="סכום ששולם" disabled  placeholder="סכום ששולם"   #Pay>
                    <!-- <p>יתרת חוב</p> -->
                    <input type="number" class="Balance"  [(ngModel)]="l.Balance" [ngModelOptions]="{standalone: true}" disabled  #Balance>
                    <!-- <p>סוג מטבע</p> -->
                  </div>
                  <div style="display: flex;">
                  
                    <ng-select class="my-class"  
                     [(ngModel)]="l.Coin" [ngModelOptions]="{standalone: true}" [items]="listcoin" bindValue="Id"  aria-placeholder="בחר שם" #val bindLabel="name"   ></ng-select>  
                    <!-- <select  class="Coin" [(ngModel)]="l.Coin" [ngModelOptions]="{standalone: true}"  placeholder="סוג מטבע"  #Coin><option *ngFor='let item of listcoin'>{{item.name}}</option></select> -->
                     <!-- <p>הערה</p> -->
                    <!-- <p>תאריך החזר הלוואה</p> -->
                    <!-- <p>תאריך לועזי</p> -->
                    <select  [(ngModel)]="l.typename" [ngModelOptions]="{standalone: true}"  aria-placehol
                    der="בחר סוג הלוואה"  #TypeOfLoan><option *ngFor ='let item of listtypeloan'>{{item.name}}</option></select>
                   
                  </div>
                  <div style="display: flex;">
                    <input type="date" (change)='GetHebroDate1()'  (ngModelChange)="l.loanDateforienHeczer= $event"  [ngModel]="l.loanDateforienHeczer| date:'y-MM-dd'"  [ngModelOptions]="{standalone: true}"  class="loanDateforienHeczer"  #loanDateHeczerf>
                   <input type="text" class="loanDateHeczer" ngDefaultControl [(ngModel)]="dateheczer" [ngModelOptions]="{standalone: true}"     placeholder="תאריך החזר הלוואה"  #loanDateHeczer>

                 
                  </div>
            
                  <div style="display: flex;">

                  <ng-select class="my-class" 
                  (change)='gethok1($event)' [(ngModel)]="l.Arev1" [ngModelOptions]="{standalone: true}" [items]="items1" bindValue="MAINID"  aria-placeholder="בחר שם" #val bindLabel="Name"   ></ng-select>  
                     <input type="text" class="Arev1cell1"  [(ngModel)]="l.Arev1cell" [ngModelOptions]="{standalone: true}"    placeholder="מספר פלאפון ערב 1"  #Arev1cell1> 
                  </div>
                  <div style="display: flex;">
  
                     <ng-select  class="my-class" 
                     (change)='gethok2($event)' [(ngModel)]="l.Arev2" [ngModelOptions]="{standalone: true}"  [items]="items1" bindValue="MAINID"  aria-placeholder="בחר שם" #val bindLabel="Name"   ></ng-select>             
                    <!-- <p>מספר פאלפון ערב2</p> -->
                    <input type="text" class="Arev1cell2"  [(ngModel)]="l.Arev2cell" [ngModelOptions]="{standalone: true}"  placeholder="מספר פלאפון ערב 2 " #Arev1cell2>
                  </div>
                  <div style="display: flex;">

                    <ng-select class="my-class" 
                    (change)='gethok3($event)' [(ngModel)]="l.Arev3" [ngModelOptions]="{standalone: true}" [items]="items1" bindValue="MAINID"  aria-placeholder="בחר שם" #val bindLabel="Name"   ></ng-select>    
                    <!-- <p>מספר פאלפון ערב3</p> --> 
                    <input type="text" class="Arev1cell3"  [(ngModel)]="l.Arev3cell" [ngModelOptions]="{standalone: true}"  placeholder=" מספר פלאפון ערב 3" #Arev1cell3>  
                  </div>       
                  <div style="display: flex;">

                    <ng-select  
                    (change)='manege($event)' [(ngModel)]="l.MAINIDMenege" [ngModelOptions]="{standalone: true}" [items]="items1" bindValue="MAINID"  aria-placeholder="הכנס שם" #val bindLabel="Name"   ></ng-select>         
                    <input type="text" class="notice"  [(ngModel)]="l.notice" [ngModelOptions]="{standalone: true}"  placeholder="הערה" #notice>
                  </div>
                  <div style="display: flex;">
<p style="margin-left: 208px;">בחר נציג</p>
                  <ng-select  
                  (change)='Contactmanege($event)' [(ngModel)]="l.MAINIDContact" [ngModelOptions]="{standalone: true}" [items]="Contacts" bindValue="MAINID"  aria-placeholder="הכנס שם" #val bindLabel="Name"   ></ng-select>         
                  </div>
  <button style="
              border-radius: 9px"  class="btn btn-primary btn-lg" (click)='addLoan()'>אישור</button>       
              </div> 
              </div>                
                </div>    
              </div>
              <app-history  [id]="idloan" [nametbl]="Heczer" [hafkada]="false" class="col-2" title="היסטוריה" style="position: absolute;top: 70px; left: 476px;width: 60px;cursor: pointer;"></app-history>              
              <app-heczers   [mainid]="id1"[iduser]="iduser" [PERMISSION]="PERMISSION" [idloan]="idloan" style="position: relative;  top:-546px;left:400px;;height: 50px;" class="table"  ></app-heczers>
              <app-task    [idloan]="idloan" [hafkada]="false" title="הוספת הוק" style="position: absolute;left: 65px;top:-167px;cursor: pointer;"></app-task>
          </div>  
      </div>   
    
      <app-showname  [id]="id1" [name]="false" class="col-2" style='position: absolute; top: 106px; left: -43px;'></app-showname>  
    </div>