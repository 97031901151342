import { Component, OnInit, Input } from '@angular/core';
import { MafkidService } from 'src/app/services/mafkid.service';
import { HokMenageService } from 'src/app/services/hok-menage.service';
import { HafkadotService } from 'src/app/services/hafkadot.service';

import { Hafkadot } from 'src/app/classes/hafkadot';
import { MaagarMenageService } from 'src/app/services/maagar-menage.service';
import { NamesService } from 'src/app/services/names.service';
import { Names } from 'src/app/classes/names';

import { LOCALE_TEXT } from 'src/app/classes/constance';
import { LoansService } from 'src/app/services/loans.service';
import { Shiabud } from 'src/app/classes/shiabud';
import { Transfer } from 'src/app/classes/transfer';
import { Coin } from 'src/app/classes/coin';
import { arrayify } from '@angular-eslint/eslint-plugin/dist/utils/utils';

@Component({
  selector: 'app-view-hafkadot',
  templateUrl: './view-hafkadot.component.html',
  styleUrls: ['./view-hafkadot.component.css']

})
export class ViewHafkadotComponent implements OnInit {
  list3: Array<Hafkadot>=new Array<Hafkadot>();

  constructor(private maf: MafkidService, private hok: HokMenageService, private name: NamesService, private haf: HafkadotService, private maagar: MaagarMenageService, private loans: LoansService) {
  }
  //#region //params
  @Input() id;
  c: any;
  rowClassRules;
  listout: any;
  listAccounts1: any;
  IDLoan: number;
  IDMAF: number;
  ShowOlder: boolean = false;
  D: boolean = false;
  Transfer: Transfer = new Transfer
  loans1: boolean
  idintbl;
  nametbl;
  mainidmaf: any;
  DateH: Date;
  tzover: number = 0;
  tzoverD: number = 0;
  listAccounts;
  num8: number = 0
  amlatotal: number = 0;;
  amla1: number = 0;;
  idloan: number;
  loanshiabud: any;
  listshiabud;
  coin: string = "שקל";
  localeText: any;
  listloans: any;
  shiabud1: Shiabud = new Shiabud()
  rowSelection: string;
  gridApi: any;
  items: Array<Names>;
  @Input() idmafkid;
  listreason: any;
  reason: string;
  idr: any;
  date1: boolean;
  @Input() PERMISSION;
  @Input() iduser;
  listcomeback;
  num2: number = 0;
  id5 = null;
  list: any;
  LIST2;
  listop;
  id1; amlab: boolean = true;
  listn: Names[];
  hafbool: boolean;
  num: number = 0;
  num1: number = 0;
  listfuture;
  listwas;
  update1: boolean;
  date: Date;
  date2: Date;
  hokreturn1;
  allbool: boolean;
  allfbool: boolean;
  onefbool: boolean;
  onebool: boolean;
  mainid;
  getbyway1;
  h: Hafkadot = new Hafkadot();
  h3: Hafkadot = new Hafkadot();
  h1: Hafkadot = new Hafkadot();
  h2: Hafkadot = new Hafkadot();
  myDate: Date = new Date();
  degel: boolean;
  degel1: boolean;
  listnames;
  degelrightclick: boolean;
  bool: boolean;
  listcoin:Array<Coin>;
  check;
  columnDefs;
  gridOptions;
  biger: boolean;
  defaultColDef;
  rowData: Array<any>;
  num3 = 0;
  //#endregion
  ngOnInit() {

    this.mainid = this.id;
     this.GetShiabud();
    if (this.PERMISSION == 3) {
      this.bool = true;
    }
    else {
      this.bool = false;
    }
    this.getreason();
    this.id1 = this.idmafkid;
    this.Getoptain();
    this.GetCoins();
    this.degel = false;
    this.degelrightclick = false;
    this.columnDefs = [
      { headerName: 'שם מפקיד', sortable: true, filter: "agTextColumnFilter", field: 'Name', width: 130 },
      {
        headerName: 'תאריך לועזי', sortable: true, field: 'DateForiegnHAfkada', sort: 'asc', filter: "agTextColumnFilter", width: 130, cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      },
      { headerName: 'תאריך הפקדה', field: 'DateHafkada', filter: "agTextColumnFilter", width: 130 },
      { headerName: 'סכום ההפקדה', sortable: true, field: 'Amount', filter: "agNumberColumnFilter", width: 100 },
      { headerName: 'עמלה', field: 'Amla', filter: "agNumberColumnFilter", width: 100 },
      { headerName: 'סכום לגביה', field: 'SUMTOGVIA', filter: "agNumberColumnFilter", width: 100 },
      { headerName: 'מטבע', sortable: true, field: 'CoinName', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'מטרה', field: 'TypeOperation', filter: "agTextColumnFilter", width: 90 },
      { headerName: 'סטטוס הפקדה', field: 'StatusHafkada', filter: "agTextColumnFilter", width: 90 },
      { headerName: 'אמצעי תשלום', field: 'getbyway', filter: "agTextColumnFilter", width: 90 },
      { headerName: 'סיבת חזרה', field: 'reasonName', filter: "agTextColumnFilter", width: 100 },

      { headerName: 'הערה', field: 'Notice', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'אסמכתא', field: 'ASMACTA', filter: "agTextColumnFilter", width: 100 },
      { headerName: 'מס"ב', field: 'MASAV', filter: "agTextColumnFilter", width: 100 },
    ];
    this.viewnameslist();
  }
  //#region del
  delete() {
    this.degelrightclick = false;
    this.GetNames();

    this.haf.DeleteHafkada(this.h2.ID);
  }
  deletehaf() {
    ;
    if (this.allbool) {
      ;
      if (window.confirm('האם ברצונך לאפס חשבון הפקדה זה?')) {
        this.haf.DeleteAllhafkadot(this.id1).then(res => {
          this.GetNames();
        })
      }
    }
    else {
      ;
      if (this.onebool) {
        this.list = this.gridApi.rowModel.rowsToDisplay

        if (window.confirm('האם ברצונך למחוק הפקדות שבוצעו?')) {
          this.haf.DeleteJustOnehafkadot(this.id1, this.gridApi.rowModel.rowsToDisplay.data.idhokhelp).then(res => {
            this.GetNames()
          })
        }
      }
      else {

        if (this.allfbool) {
          this.haf.DeleteAllFuturehafkadot(this.id1).then(res => {
            this.GetNames()
          });
        }
        else {
          ;
          this.list = this.gridApi.rowModel.rowsToDisplay

          this.haf.DeleteJustOneFuturehafkadot(this.id1, this.gridApi.rowModel.rowsToDisplay.data.idhokhelp).then(res => {
            this.GetNames()
          });
        }
      }
    }
    ;
    // this.showHafkadot(this.idmafkid);
    this.GetNames();
  }
  justone() {
    this.onebool = true;
    this.allbool = false;
    this.onefbool = false;
    this.allfbool = false;
  }
  all() {
    this.allbool = true;
    this.onebool = false;
    this.onefbool = false;
    this.allfbool = false;
  }
  justonef() {
    this.onefbool = true;
    this.onebool = false;
    this.allbool = false;
    this.allfbool = false;
  }
  allf() {
    this.allfbool = true;
    this.onebool = false;
    this.allbool = false;
    this.onefbool = false;

  }
  //#endregion
  //#region  bool
  big() {
    this.biger = true;
  }
  less() {
    this.biger = false;
  }
  savecoin(val: string) {
    this.coin = val;
  }
  saver(val: string) {
    this.reason = val;
    ;
  }
  savedate(d: Date) {
    this.date2 = d;
  }
  changeval(value: string) {
    this.hokreturn1 = value;
    ;
    if (this.hokreturn1 == "בתאריך אחר") {
      this.date1 = true;
    }
  }
  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  fun(event) {

    this.h2 = event.data;
    this.date = this.h2.DateForiegnHAfkada;
    this.degelrightclick = !this.degelrightclick;
  }
  hafkada() {
    this.hafbool = true;
  }
  amla() {
    this.amlab = !this.amla;
  }
  drag() {
    this.hafbool = false;
  }
  getbyway(v: string) {
    this.getbyway1 = v;
  }
  //#endregion
  //#region get data and show
  ShowDolars() {
    this.EditList(2);
  }
  ShowShekel() {
    this.EditList(1);
  }
  buildgrid() {
    this.rowClassRules = {
      'status1': function (params) { return params.data.StatusHafkada == "חזר"; },
      'status3': function (params) { return params.data.StatusHafkada == "בוצע"; },
      'status2': function (params) { return params.data.TypeOperation == "משיכה"; }
    },
      this.localeText = LOCALE_TEXT,
      this.columnDefs = this.columnDefs,
      this.rowSelection = 'single',
      this.rowData = this.list;
  }
  // showNames() {
  // this.name.ShowNames().then(res => {
  //   this.listnames = res;
  // this.showHafkadot(this.id1);
  // });
  // }
  showHafkadot(id: number) {
    this.maf.ViewHafkadot(id).then(res => {
      this.list = res; 
      this.LIST2 = this.list;
  
      debugger
      this.list.forEach(element => {
        element.SUMTOGVIA = element.Amla + element.Amount;
      });
      if (this.list.length > 0) {

        this.EditList(this.list[0].Coin);
      }
    })
  }
  Getoptain() {
    this.hok.getlistoptains().then(res => {
      this.listop = res;
    })
  }
  GetCoins() {
  const s = JSON.parse(sessionStorage.getItem('ListCoins'));
    if (s == null||s == false) {
      this.maagar.GetCoins().then(res => {
        this.listcoin = res;
        sessionStorage.setItem('ListCoins', JSON.stringify(this.listcoin));
        // this.GetNames();
      })
    }
    else {
      this.listcoin=s;
      if (this.listcoin != null) {
        this.GetNames();
      }
    }
  }
  GetNames() {
    this.maf.getlistNames(this.id1).then(res => {
      this.listn = res;
      ;
      this.listn.forEach(element => {
        element['Name'] = element['FamilyName'] + " " + element['FirstName'];
      })
      this.showHafkadot(this.id1);
      //   if (this.listn.length > 1) {
      //     this.listn.push(new Names());
      //   }
    })
  }
  getreason() {
        this.hok.getreason().then(res => {
  this.listreason = res;
      if (this.listreason.length > 0) {
        this.reason = this.listreason[0].name;
      }
    })
  }
  //#endregion
  //#region function
  resettotalcount() {
    this.maf.resettotalcount(this.idmafkid);
  }
  AddToList(id: number) {
  }
  excel() {
    ;
    this.gridApi.exportDataAsCsv(this.gridApi.rowModel.rowsToDisplay);
  }
  viewdetails(id: number) { }

  view() {

    this.haf.View(this.idmafkid, this.DateH, this.biger)
      .then(res => {
        this.list = res;
        this.LIST2 = this.list;
        if (this.list.length > 0) {
          this.EditList(this.list[0].Coin);
        }
      })
  }
  PrintReport()
  {
    this.maf.reportsToOneRow(this.h2,false)
  }
  StopHafkadot(num: number) {
    
    if (this.h2.TypeOperation == "הפקדה" && (this.h2.StatusHafkada == "קבע-דחוי" || this.h2.StatusHafkada == "אשראי-דחוי" || this.h2.StatusHafkada == "שיק-דחוי")) {
      this.degelrightclick = false;
      this.maf.Stophafkadot(this.h2.ID, num).then(res => { this.GetNames() });
    }
    else {
      alert("ניתן להשהות רק תשלומים ");
    }
  }
  filterChanged() {
    this.LIST2 = new Array<Hafkadot>();
    this.gridApi.rowModel.rowsToDisplay.forEach(element => {
      this.LIST2.push(element.data)
    });
    this.EditList(this.list[0].Coin)

  }
  EditList(coin: number): number {
    if (coin == 2) {
      this.D = true;
    }
    else {
      this.D = false;
    }
    this.listfuture = this.LIST2.filter(a => (a.StatusHafkada == "קבע-דחוי" || a.StatusHafkada == "שיק-דחוי" || a.StatusHafkada == "אשראי-דחוי") && a.Coin == coin);
    this.listwas = this.LIST2.filter(a => a.StatusHafkada == "בוצע" && a.Coin == coin && a.TypeOperation == "הפקדה" || a.TypeOperation == "תרומה");
    
    this.listout = this.LIST2.filter(a => a.StatusHafkada == "בוצע" && a.Coin == coin && a.TypeOperation == "משיכה");
    this.listcomeback = this.LIST2.filter(a => a.StatusHafkada == "חזר" && a.Coin == coin);
    this.num1 = 0; this.num2 = 0; this.num = 0; this.amla1 = 0; this.amlatotal = 0;
    this.listfuture.forEach(element => {
      if (element.TypeOperation != "תרומה") {
        this.num += element.Amount;
        this.amlatotal += element.Amla;

      }
      else {

        this.amlatotal += element.Amount + element.Amla;
      }

    })
    this.listwas.forEach(element => {
      if (element.TypeOperation != "תרומה") {
        this.num1 += element.Amount;
        this.amla1 += element.Amla;
      }
      else {
        this.amla1 += element.Amount + element.Amla;

      }
    })
    this.listout.forEach(element => {
      this.num1 -= element.Amount;
      this.amla1 -= element.Amla;
    })
    this.listcomeback.forEach(element => { this.num2 += 1; })
    this.num1 > -1 ? this.num += this.num1 : this.num

    if (this.D) {
      this.num8 = this.num1 - this.tzoverD;
    }
    else {
      this.num8 = this.num1 - this.tzover;
    }
    this.amlatotal += this.amla1
    this.list.forEach(element => {
      element['CoinName'] = this.listcoin && this.listcoin?.find(a => a.Id == element.Coin) ? this.listcoin?.find(a => a.Id == element.Coin).name : '';
      element['reasonName'] = this.listreason && this.listreason?.find(a => a.Id == element.reason) ? this.listreason?.find(a => a.Id == element.reason).name : '';

      element['Name'] = this.listn && this.listn?.find(a => a.MAINID == element.MAINID) ? this.listn?.find(a => a.MAINID == element.MAINID)['Name'] : 
       this.items.find(a => a.MAINID == element.MAINID)? this.items.find(a => a.MAINID == element.MAINID)['Name']:'';
    })
    this.rowData = this.list;
    this.buildgrid();
    return this.num8;
  }
  pay(remark: string) {
    if(remark==""||remark==null)
    remark="מזומן"
    if (this.h2.StatusHafkada != "בוצע" && this.h2.StatusHafkada != "חזר" && this.h2.TypeOperation == "הפקדה") {
      this.degelrightclick = false;

      this.maf.pay(this.h2.ID, remark).then(res => { this.GetNames() });
    }
    else {
      alert("לא ניתן לסמן כשולם");
    }
  }
  hokreturn() {
    // if (this.h2.StatusHafkada == "בוצע" && this.h2.TypeOperation == "הפקדה") {
    this.degelrightclick = false;
    this.idr = this.listreason && this.listreason.find(a => a.name == this.reason) ? this.listreason.find(a => a.name == this.reason).Id : null;
    this.maf.HokReturn(this.h2.ID, this.hokreturn1, this.amlab, this.iduser, this.date2, this.idr).then(res => { this.GetNames() });
    // }
    // else {
    //   alert("לא ניתן לסמן כחזר");
    // }
  }
  update(event) {

    this.h = this.LIST2.find(a => a.ID == event.data.ID);
    document.getElementById('id05').style.display = "block"
  }
  changescum() {
    this.h.SUMTOGVIA = this.h.Amla + this.h.Amount;
  }
  changeva2(event) {
    this.mainidmaf = event.MAINID;
  }
  save(d: Date) {
    if (this.mainidmaf == null) {
      this.mainidmaf = this.mainid;
    }
    this.h3.MAINID = this.mainidmaf;
    this.h3.Updateby = this.iduser;
    this.h3.Createby = this.iduser;
    this.h3.Coin = this.listcoin && this.listcoin.find(a => a.name == this.coin) ? this.listcoin.find(a => a.name == this.coin).Id : null;
    this.h3.DateForiegnHAfkada = d;
    this.h3.StatusHafkada = "בוצע";
    this.h3.MafkidID = this.id1;
    if (this.getbyway1 == null) {
      this.getbyway1 = "אשראי";
    }
    this.h3.getbyway = this.getbyway1;
    if (this.hafbool) {
      this.h3.TypeOperation = "הפקדה"
      this.haf.addHafkadot(this.h3);
      this.h3 = new Hafkadot();
    }
    else {
      this.h3.TypeOperation = "משיכה"

      if (this.h3.Amount > this.EditList(this.h3.Coin)) {
        if (confirm("הסכום שברצונך למשוך גבוה מהסכום שזמין למשיכה האם ברצונך להמשיך?")) {
          this.haf.addHafkadot(this.h3);
          this.h3 = new Hafkadot();
        }
      }
      else {
        this.haf.addHafkadot(this.h3);
        this.h3 = new Hafkadot();
      }
    }
  }
  UpdateHaf() {
    debugger
    this.h.Coin = this.listcoin && this.listcoin.find(a => a.name == this.h.CoinName) ? this.listcoin.find(a => a.name == this.h.CoinName).Id : null;
    this.haf.UpdateHaf(this.h);

  }
  //#endregion
  //#region  TRANSFERINIT

  ShowLoansORMafkids(event) {
    if (this.loans1) {
      this.ShowLoans(event);
    }
    else {
      this.maf.GetTotalBymainid(event.MAINID).then(res => {
        this.listAccounts1 = res

        this.maf.showhafkadacount(event.MAINID).then(res => {
          this.listAccounts = res;

          this.listAccounts.forEach(element => {
            element['Totalhafkada'] = this.listAccounts1 && this.listAccounts1.find(a => a.idmafkid == element.Id && a.type == "הפקדה") ? this.listAccounts1.find(a => a.idmafkid == element.Id && a.coin == 1 && a.type == "הפקדה").scum : '';
            element['Totaltruma'] = this.listAccounts1 && this.listAccounts1.find(a => a.idmafkid == element.Id && a.type == "תרומה") ? this.listAccounts1.find(a => a.idmafkid == element.Id && a.coin == 1 && a.type == "תרומה").scum : '';
          })
        })
      })
    }
  }

  SaveTransfer(scum: number, coin: string) {
    this.Transfer.scum = scum;
    if (this.loans1) {
      this.Transfer.MAINIDOTHER = this.IDLoan;
    }
    else {
      this.Transfer.MAINIDOTHER = this.IDMAF;
    }
    this.Transfer.LOAN = this.loans1;
    this.Transfer.MYMAINID = this.idmafkid;
    this.Transfer.coin = this.listcoin && this.listcoin.find(a => a.name == coin) ? this.listcoin.find(a => a.name == coin).Id : null;
    
    this.maf.SaveTransfer(this.Transfer);

  }
  CooseMafkis(id: number) {
    this.IDMAF = id;
    ;
  }
  CooseLoan(id: number) {
    this.IDLoan = id;

  }
  isloan() {
    this.loans1 = true

  }
  isloan1() {
    this.loans1 = false
  }
  //#endregion
  //#region shiabud
  shiabud(scum: number) {
    this.shiabud1.Amount = scum;
    this.shiabud1.idloan = this.idloan;
    this.shiabud1.Idmafkid = this.idmafkid;
    if (this.shiabud1.Idmafkid && this.shiabud1.Idmafkid && this.shiabud1.Idmafkid)
      this.haf.Shiabud(this.shiabud1);
  }
  chooseLoan(id: number) {
    this.idloan = id;
  }

  ShowLoans(event) {
    this.name.Showoans(event.MAINID).then(res => {
      this.listloans = res
    })
  }
  GetLoansShiabud() {

    this.maf.GetLoansShiabud(this.idmafkid).then(res => {
      this.loanshiabud = res
      
      if (res != null) {
        this.loanshiabud.forEach(element => {
          element["Scum"] = this.listshiabud && this.listshiabud.find(a => a.idloan == element.ID) ? this.listshiabud.find(a => a.idloan == element.ID).Amount : 0
          if (element.Pay != null) {
            element["ScumAfter"] = element["Scum"] - element.Pay

          }
          element["Name"] = this.items && this.items.find(a => a.MAINID == element.MAINID) ? this.items.find(a => a.MAINID == element.MAINID).FamilyName : ''

          this.c = this.listshiabud && this.listshiabud.find(a => a.idloan == element.ID) ? this.listshiabud.find(a => a.idloan == element.ID).Coin : 1
          if (this.c == 1) {

            this.tzover += element["Scum"];
            if (element.Pay != null) {
              this.tzover -= element.Pay
            }
          }
          else {
            this.tzoverD += element["Scum"];
            if (element.Pay != null) {
              this.tzoverD -= element.Pay
            }
          }

        })
      };
    })
  };
  showolderloans() {
    this.ShowOlder = true;
  }
  GetShiabud() {
    this.maf.GETShiabud(this.idmafkid).then(res => {
      this.listshiabud = res
      this.GetLoansShiabud();
    })
  };
  new() {
    this.ShowOlder = false;
  }
  viewnameslist() {
    this.items = JSON.parse(sessionStorage.getItem('ListNames'));
    if (this.items == null) {
      this.name.ShowNames().then(res => {
        this.items = res;
      })
    }
    if (this.items != null) {
      this.items.forEach(element => {
                    element['Name'] = element['FamilyName'] !=null?element['FamilyName']:''+ " " + element['FirstName']!=null?element['FirstName']:''
      })
    }

  }
  //#endregion
}


